import React, { useId, useState } from "react";
import { imageFileToDataURL } from "../common/functions";

interface Props {
    disabled: boolean
    onChange: (image: File) => void;
}

export default function ImagePicker(props: Props) {
    const fileInputId = useId();
    const imageElemId = useId();

    const allowedFileTypes = ["image/jpeg", "image/png", "image/bmp"];
    const [error, setError] = useState("");
    const [imageElemURL, setImageElemURL] = useState("");

    const getErrorComponent = () => {
        if (error == "") {
            return <></>;
        }
        return (<div className="alert alert-danger image-picker-error" role="alert">
            {error}
        </div>);
    };

    const selectImage = () => {
        if (props.disabled) {
            return;
        }

        setError("");

        const fileInput = document.getElementById(fileInputId);
        if (!fileInput) {
            console.log("File input not found");
            setError("An error occured");
            return;
        }

        fileInput.click();
    };

    const onInputChange = () => {
        setError("");

        const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
        if (!fileInput) {
            console.log("File input not found");
            setError("An error occured");
            return;
        }

        const files = fileInput.files;
        if (!files) {
            console.log("Files not found");
            setError("An error occured");
            return;
        }

        if (files.length != 1) {
            return;
        }

        const file = files[0];
        if (allowedFileTypes.indexOf(file.type) == -1) {
            setError(`Image must be one of type: ${allowedFileTypes.join(", ")}`);
            return;
        }

        try {
            const dataURL = imageFileToDataURL(file);
            setImageElemURL(dataURL);
            props.onChange(file);
        } catch (e) {
            const err = e as Error;
            console.log(err.message);
            setError("An error occured");
            return;
        }
    };

    return (<>
        <div className="image-picker" onClick={() => selectImage()}>
            <input type="file" id={fileInputId} accept="image/jpeg,image/png,image/bmp" onChange={onInputChange} />
            {imageElemURL == "" && <span>Select Image</span>}
            {imageElemURL != "" && <img id={imageElemId} src={imageElemURL} />}
        </div>

        {getErrorComponent()}
    </>);
}