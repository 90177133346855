import { BoundingBox } from "./schema";

const imageFileToDataURL = (image: Blob) => {
    return URL.createObjectURL(image);
};

const drawBoundingBoxOnImage = (image: Blob, boundingBox: BoundingBox) => {
    return new Promise<Blob>((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            const context = canvas.getContext("2d");
            if (!context) {
                reject(new Error("An error occured while getting the context"));
                return;
            }

            context.drawImage(img, 0, 0);

            context.strokeStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`;
            context.lineWidth = 5;
            context.strokeRect(
                boundingBox.v0.x * img.width,
                boundingBox.v0.y * img.height,
                (boundingBox.v1.x - boundingBox.v0.x) * img.width,
                (boundingBox.v1.y - boundingBox.v0.y) * img.height
            );

            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Blob can not be null"));
                    return;
                }
                resolve(blob);
            });
        };

        img.src = URL.createObjectURL(image);
    });
};

export { imageFileToDataURL, drawBoundingBoxOnImage };