import axios from "axios";
import config from "../common/config";
import { AnalysisResult, PredictionClasses } from "../common/schema";

interface PredictionResponse {
    success: true,
    payload: AnalysisResult
}

interface ClassesResponse {
    success: true,
    payload: PredictionClasses
}

interface APIError {
    success: false
    error: {
        code: number
        type: string
        message: string
    },
}

export default class BackendService {
    static async predictImageClass(image: File) {
        const response = await axios.post<PredictionResponse | APIError | string>(`${config.services.backend.baseURL}/predict`, await image.arrayBuffer(), {
            headers: {
                "Content-Type": image.type,
            },
        });
        if (typeof response.data != "object") {
            throw new Error(response.data);
        }

        if (!response.data.success) {
            throw new Error(response.data.error.message);
        }

        return response.data.payload;
    }

    static async getPredictionClasses() {
        const response = await axios.get<ClassesResponse | APIError | string>(`${config.services.backend.baseURL}/classes`);
        if (typeof response.data != "object") {
            throw new Error(response.data);
        }

        if (!response.data.success) {
            throw new Error(response.data.error.message);
        }

        return response.data.payload;
    }
}