import React, { useEffect, useState } from "react";
import ImagePicker from "./components/ImagePicker";
import BackendService from "./services/backend-service";
import { AnalysisResult, PredictionClasses } from "./common/schema";
import { drawBoundingBoxOnImage } from "./common/functions";

function App() {
    const [error, setError] = useState("");
    const [analyzing, setAnalyzing] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);
    const [analysedImage, setAnalysedImage] = useState<string>("");
    const [predictionClasses, setPredictionClasses] = useState<PredictionClasses | null>(null);

    const getErrorComponent = () => {
        if (error == "") {
            return <></>;
        }
        return (<div className="alert alert-danger image-picker-error" role="alert">
            {error}
        </div>);
    };

    const onImageSelect = (image: File) => {
        setSelectedImage(image);
        setAnalysisResult(null);
        setAnalysedImage("");
    };

    const getAnalysisResultComponent = () => {
        if (analyzing) {
            return (<div className="row mt-3">
                <div className="col-md-6 offset-md-3 d-flex justify-content-center p-5">
                    <div className="spinner-border text-dark" style={{ "width": "5rem", "height": "5rem" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>);
        }

        if (!analysisResult || !selectedImage || !predictionClasses) return (<></>);

        const accordionId = "analysis_accordion";

        return (<div className="row mt-3">
            <div className="col-md-8 offset-md-2 border border-1 border-dark rounded p-3">
                <h3>Results</h3>

                <div className="d-flex justify-content-center">
                    {analysedImage != "" && <img className="img-fluid" src={analysedImage} />}
                </div>

                <div className="accordion mt-3" id={accordionId}>
                    {
                        Object.entries(analysisResult.classPredictions)
                            .filter(([, score]) => score > 0.01)
                            .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                            .map(([diseaseClass, score], i) => {
                                const collapseId = `${diseaseClass}_collapse`;
                                const classData = predictionClasses[diseaseClass];

                                return (<div key={diseaseClass} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${i != 0 ? "collapsed" : ""}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#${collapseId}`}
                                            aria-expanded={i == 0 ? "true" : "false"}
                                        >
                                            <span className="text-capitalize">{diseaseClass.replaceAll("_", " ")}</span>
                                            <span className="ms-2">{(score * 100).toFixed(2)}%</span>
                                            {i == 0 && <span className="badge bg-primary ms-2">Best Match</span>}
                                        </button>
                                    </h2>

                                    <div id={collapseId} className={`accordion-collapse collapse ${i == 0 ? "show" : ""}`} data-bs-parent={`#${accordionId}`}>
                                        <div className="accordion-body">
                                            <span className="d-block lead" style={{ whiteSpace: "break-spaces" }}>{classData.description}</span>

                                            {classData.treatments.length > 0 && <span className="d-block lead fw-bold mt-3 mb-2">Treatments</span>}

                                            {classData.treatments.length > 0 && <ul>
                                                {classData.treatments.map((treatment, i) => <li key={i} className="lead my-2">{treatment}</li>)}
                                            </ul>}
                                        </div>
                                    </div>
                                </div>);
                            })
                    }
                </div>
            </div>
        </div>);
    };

    const analyzeSelectedImage = async () => {
        if (!selectedImage) {
            return;
        }

        setAnalysisResult(null);
        setAnalysedImage("");
        setAnalyzing(true);
        setError("");

        if (!predictionClasses) {
            try {
                const result = await BackendService.getPredictionClasses();
                setPredictionClasses(result);
            } catch (e) {
                console.log((e as Error).message);
                setError((e as Error).message);
                setAnalyzing(false);
                return;
            }
        }

        try {
            const result = await BackendService.predictImageClass(selectedImage);
            setAnalysisResult(result);
        } catch (e) {
            console.log((e as Error).message);
            setError((e as Error).message);
        } finally {
            setAnalyzing(false);
        }
    };

    useEffect(() => {
        if (selectedImage == null || analysisResult == null) {
            return;
        }

        (async () => {
            const boundedImage = await drawBoundingBoxOnImage(selectedImage, analysisResult.boundingBox);
            setAnalysedImage(URL.createObjectURL(boundedImage));
        })();
    }, [selectedImage, analysisResult]);

    return (
        <div>
            <div className="container py-4 px-3 mx-auto">

                <div className="row">
                    <div className="col-md-12">
                        <h1>Tomato Leaf Disease Detector</h1>
                        <p className="lead">Please select a tomato leaf image below:</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <ImagePicker disabled={analyzing} onChange={onImageSelect} />
                    </div>
                </div>

                <div className="row mt-3 mb-5">
                    <div className="col-md-6 offset-md-3 d-flex flex-column align-items-center">
                        <button className="btn btn-primary" disabled={selectedImage == null || analyzing} onClick={analyzeSelectedImage}>
                            {analyzing ? "Analyzing" : "Analyze"}
                        </button>
                        {getErrorComponent()}
                    </div>
                </div>

                {getAnalysisResultComponent()}

                <div className="row mt-5">
                    <div className="col-md-12">
                        <p className="text-center">Developed By Mohammed Adekunle.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
